<div class=" session " id="apropos">
    <div class="container ">
        <!-- FRENCH PART -->
        <div *ngIf="switches.langSwitch == false" class="quiSommesnous box-shadow white ">
            <div class="row">
                <div class="col-lg-7 col-md-7 col-sm-7">
                    <div class="card-accueil row">
                        <div class="col-md-12 ">
                            <div class="apropos">
                                <h3 class="item--title st-line-left1"><span><i></i>
                                La satisfaction cliente au cœur de notre démarche</span>
                                </h3>
                                <div class="item--desc text-justify" style="padding: 20px;padding-left:82px;">

                                    Le groupe Modelis s'est créé et se développe autour d'une unique motivation : répondre aux besoins de nos clients. C'est pourquoi, il s'est adapté au fil des années en spécialisant ses équipes autour des trois domaines prioritaires : la topographie, la
                                    géomatique et le génie civil.<br><br> La base du groupe Modelis se trouve en Côte d'ivoire. Nous restons attachés à cela et ambitionnons de créer une multi-nationale ancrée dans les
                                    valeurs et la culture africaine.<br><br> Partout où le groupe Modelis ira, le client sera toujours au cœur de notre attention. C'est notre motivation et celle de nos équipes au quotidien : offrir la plus grande valeur
                                    ajoutée à nos clients.<br><br> Nous espérons collaborer avec vous sur vos projets et vous apporter toute notre expertise.

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="item--holder wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                                        <h2><i class="fa fa-angle-double-right"></i> Notre Vision
                                        </h2>
                                        <div class="item--desc">Nous pensons que la géomatique peut révolutionner votre compréhension du monde.
                                        </div>
                                    </div>
                                    <div class="item--holder wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                                        <h2>
                                            <i class="fa fa-angle-double-right"></i>Notre Mission
                                        </h2>
                                        <div class="item--desc">
                                            Nous démocratisons l’accès à l’information géographique.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="item--holder wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                                        <h2><i class="fa fa-angle-double-right"></i> Nos valeurs
                                        </h2>
                                        <div class="item--desc">
                                            Expertise, objectivité , intégrité et respect des autres
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-5 col-sm-5 wow fadeInUp " style="margin:auto;visibility: visible; animation-name: fadeInUp;">
                    <div class="">
                        <img style="
                            height: auto;
                            max-width: 100%;
                            border: none;
                            -webkit-border-radius: 0;
                            border-radius: 0;
                            -webkit-box-shadow: none;
                            box-shadow: none;
                            " class="img-responsive img-center" src="{{img}}assets/images/quisommesnous.png">
                    </div>

                </div>
            </div>
        </div>
        <!-- END FRENCH PART -->

        <!-- ENGLISH PART -->
        <div *ngIf="switches.langSwitch == true" class="quiSommesnous box-shadow white ">
            <div class="row">
                <div class="col-lg-7 col-md-7 col-sm-7">
                    <div class="card-accueil row">
                        <div class="col-md-12 ">


                            <div class="apropos">
                                <h3 class="item--title st-line-left1"><span><i></i>
                                  Customer satisfaction is at the heart of our approach</span>
                                </h3>
                                <div class="item--desc text-justify" style="padding: 20px;padding-left:82px;">
                                    Modelis group is created and develops around a single motivation: to meet customers’ needs. This is why it has adapted over the years by specializing its teams around the three priority areas: topography, geomatics and civil engineering.<br><br>                                    The base of Modelis group is in Cote d’Ivoire. We remain attached to this and aim to create a multinational based on African values and culture. <br><br> Wherever Modelis group goes, customers would always be at the
                                    heart of our attention. It’s our motivation and those of our team on a daily basis: to offer the greatest added value to our customers. <br><br> We hope to collaborate with you on your projects and to bring you all
                                    our expertise.
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="item--holder wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                                        <h2><i class="fa fa-angle-double-right"></i> Our Vision
                                        </h2>
                                        <div class="item--desc">We believe that geomatics can revolutionize your understanding of the world.
                                        </div>
                                    </div>
                                    <div class="item--holder wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                                        <h2>
                                            <i class="fa fa-angle-double-right"></i>Our Mission
                                        </h2>
                                        <div class="item--desc">
                                            We democratize access to geographic information.
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="item--holder wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                                        <h2><i class="fa fa-angle-double-right"></i> Our Values
                                        </h2>
                                        <div class="item--desc">
                                            Expertise, objectivity, integrity and respect of others.
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div class="col-lg-5 col-md-5 col-sm-5 wow fadeInUp " style="margin:auto;visibility: visible; animation-name: fadeInUp;">
                    <div class="">
                        <img style="
                          height: auto;
                          max-width: 100%;
                          border: none;
                          -webkit-border-radius: 0;
                          border-radius: 0;
                          -webkit-box-shadow: none;
                          box-shadow: none;
                          " class="img-responsive img-center" src="{{img}}assets/images/quisommesnous.png">
                    </div>

                </div>
            </div>
        </div>
        <!-- END ENGLISH PART -->
    </div>
</div>