// variablesGlobales.ts
import {Injectable} from '@angular/core';

@Injectable()

export class Variable {

    constructor(){
    }

    BASE_IMG: string = 'https://modelisgroup.com/';
    BASE_API_URL: string = 'https://modelisgroup.com/api/public/';
    // BASE_IMG:string='';
    // user;
}

