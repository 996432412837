import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Variable} from '../../service/Variable';

@Injectable({
  providedIn: 'root',
})
export class ContactsController {

  constructor(private http: HttpClient, private app: Variable) {}

  BASE_API_URL = this.app.BASE_API_URL;

  mail(nom, prenom, email, telehone, objet, message) {
    let body = new URLSearchParams();
    body.set('nom', nom);
    body.set('prenom', prenom);
    body.set('email', email);
    body.set('telehone', telehone);
    body.set('objet', objet);
    body.set('message', message);
    return this.http.post(this.BASE_API_URL + 'contacts/sendMail',
      body.toString(),
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
      }
    );
  }
}
