<div class="container top--20">
    <div class="row">
        <div class="MultiCarousel" data-items="1,3,5,6" data-slide="1" id="MultiCarousel" data-interval="1000">
            <div class="MultiCarousel-inner">

                <div class="item col-md-4 wow fadeInUp " style="visibility: visible; animation-name: fadeInUp;">
                    <!-- FRENCH PART -->
                    <div *ngIf="switches.langSwitch == false" class="card-service text-center">
                        <div class="grid-item-over">
                            <div class="item--featured">
                                <img src="{{img}}assets/images/slider/GPS-5.png" class="img-responsive img-center" alt="theme-04" title="theme-04">
                            </div>
                            <div class="item--holder">
                                <div class="item--holder-inner">
                                    <div class="item--icon">
                                        <span class="round-tabs">
                                        <p>2011</p>
                                    </span>
                                    </div>
                                    <h3 class="item--title">
                                        <img src="{{img}}assets/images/logos/GIS-logo.png" class="img-responsive img-center">
                                    </h3>
                                    <div class="item--gap"></div>
                                    <div class="item--content">
                                        Le début de l'aventure du groupe Modelis en 2011. Cette entreprise est créée pour faire face à la demande croissante en matière de matériel de topographie.
                                    </div>
                                </div>
                            </div>
                            <div class="item--meta">
                                <h3 class="item--title">
                                    <a target="_blank" href="https://modelis-gis.com" tabindex="0">
                                        Modelis-GIS</a></h3>
                                <div class="item--readmore">
                                    <a target="_blank" class="more-plus" href="https://modelis-gis.com" tabindex="0"><i class="fa fa-eye text-gradient"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END FRENCH PART -->

                    <!-- ENGLISH PART -->
                    <div *ngIf="switches.langSwitch == true" class="card-service text-center">
                        <div class="grid-item-over">
                            <div class="item--featured">
                                <img src="{{img}}assets/images/slider/GPS-5.png" class="img-responsive img-center" alt="theme-04" title="theme-04">
                            </div>
                            <div class="item--holder">
                                <div class="item--holder-inner">
                                    <div class="item--icon">
                                        <span class="round-tabs">
                                      <p>2011</p>
                                  </span>
                                    </div>
                                    <h3 class="item--title">
                                        <img src="{{img}}assets/images/logos/GIS-logo.png" class="img-responsive img-center">
                                    </h3>
                                    <div class="item--gap"></div>
                                    <div class="item--content">
                                        The start of the Modelis group adventure in 2011. This company was created to meet the growing demand for surveying equipment
                                    </div>
                                </div>
                            </div>
                            <div class="item--meta">
                                <h3 class="item--title">
                                    <a target="_blank" href="https://modelis-gis.com" tabindex="0">
                                      Modelis-GIS</a></h3>
                                <div class="item--readmore">
                                    <a target="_blank" class="more-plus" href="https://modelis-gis.com" tabindex="0"><i class="fa fa-eye text-gradient"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END ENGLISH PART -->
                </div>

                <div class="item col-md-4 wow fadeInUp " style="visibility: visible; animation-name: fadeInUp;">
                    <!-- FRENCH PART -->
                    <div *ngIf="switches.langSwitch == false" class="card-service text-center">
                        <div class="grid-item-over">
                            <div class="item--featured">
                                <img src="{{img}}assets/images/slider/tech.png" class="img-responsive img-center" alt="theme-04" title="theme-04">
                            </div>
                            <div class="item--holder">
                                <div class="item--holder-inner">
                                    <div class="item--icon">
                                        <span class="round-tabs">
                                        <p>2015</p>
                                    </span>
                                    </div>
                                    <h3 class="item--title">
                                        <img src="{{img}}assets/images/logos/TECH-logo.png" class="img-responsive img-center">
                                    </h3>
                                    <div class="item--gap"></div>
                                    <div class="item--content">
                                        Après la vente de matériel en topographie, la base du groupe Modelis diversifie ses activités en créant la division Géomatique avec une équipe dédie pour répondre à un besoin croissant de projet en matière d'information géographique
                                    </div>

                                </div>
                            </div>
                            <div class="item--meta">
                                <h3 class="item--title">
                                    <a target="_blank" href="https://modelis-tech.com" tabindex="0">
                                        Modelis-Tech</a></h3>
                                <div class="item--readmore">
                                    <a target="_blank" class="more-plus" href="https://modelis-tech.com" tabindex="0"><i class="fa fa-eye text-gradient"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END FRENCH PART -->

                    <!-- ENGLISH PART -->
                    <div *ngIf="switches.langSwitch == true" class="card-service text-center">
                        <div class="grid-item-over">
                            <div class="item--featured">
                                <img src="{{img}}assets/images/slider/tech.png" class="img-responsive img-center" alt="theme-04" title="theme-04">
                            </div>
                            <div class="item--holder">
                                <div class="item--holder-inner">
                                    <div class="item--icon">
                                        <span class="round-tabs">
                                      <p>2015</p>
                                  </span>
                                    </div>
                                    <h3 class="item--title">
                                        <img src="{{img}}assets/images/logos/TECH-logo.png" class="img-responsive img-center">
                                    </h3>
                                    <div class="item--gap"></div>
                                    <div class="item--content">
                                        After the sale of equipment in topography, the base of the Modelis group diversifies its activities by creating the Geomatics division with a dedicated team to meet a growing need for projects in terms of geographical information.
                                    </div>

                                </div>
                            </div>
                            <div class="item--meta">
                                <h3 class="item--title">
                                    <a target="_blank" href="https://modelis-tech.com" tabindex="0">
                                      Modelis-Tech</a></h3>
                                <div class="item--readmore">
                                    <a target="_blank" class="more-plus" href="https://modelis-tech.com" tabindex="0"><i class="fa fa-eye text-gradient"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END ENGLISH PART -->
                </div>

                <div class="item col-md-4 wow fadeInUp " style="visibility: visible; animation-name: fadeInUp;">
                    <!-- FRENCH PART -->
                    <div *ngIf="switches.langSwitch == false" class="card-service text-center">
                        <div class="grid-item-over">
                            <div class="item--featured">
                                <img src="{{img}}assets/images/slider/france.png" class="img-responsive img-center" alt="theme-04" title="theme-04">
                            </div>
                            <div class="item--holder">
                                <div class="item--holder-inner">
                                    <div class="item--icon">
                                        <span class="round-tabs">
                                        <p>2015</p>
                                    </span>
                                    </div>
                                    <h3 class="item--title">
                                        <img src="{{img}}assets/images/logos/France.jpg" class="img-responsive img-center">
                                    </h3>
                                    <div class="item--gap"></div>
                                    <div class="item--content">
                                        Le groupe ouvre sa branche européenne en France pour fournir son expertise à différents grands comptes. Cette branche est spécialisée en Consulting en Géomatique.
                                    </div>

                                </div>
                            </div>
                            <div class="item--meta">
                                <h3 class="item--title">
                                    <a target="_blank" href="https://modelis-france.com" tabindex="0">
                                        Modelis-France</a>
                                </h3>
                                <div class="item--readmore">
                                    <a target="_blank" class="more-plus" href="https://modelis-france.com" tabindex="0"><i class="fa fa-eye text-gradient"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END FRENCH PART -->

                    <!-- ENGLISH PART -->
                    <div *ngIf="switches.langSwitch == true" class="card-service text-center">
                        <div class="grid-item-over">
                            <div class="item--featured">
                                <img src="{{img}}assets/images/slider/france.png" class="img-responsive img-center" alt="theme-04" title="theme-04">
                            </div>
                            <div class="item--holder">
                                <div class="item--holder-inner">
                                    <div class="item--icon">
                                        <span class="round-tabs">
                                      <p>2015</p>
                                  </span>
                                    </div>
                                    <h3 class="item--title">
                                        <img src="{{img}}assets/images/logos/France.jpg" class="img-responsive img-center">
                                    </h3>
                                    <div class="item--gap"></div>
                                    <div class="item--content">
                                        The group opens its European branch in France to provide its expertise to various key accounts. This branch is specialized in Consulting in Geomatics.
                                    </div>

                                </div>
                            </div>
                            <div class="item--meta">
                                <h3 class="item--title">
                                    <a target="_blank" href="https://modelis-france.com" tabindex="0">
                                      Modelis-France</a>
                                </h3>
                                <div class="item--readmore">
                                    <a target="_blank" class="more-plus" href="https://modelis-france.com" tabindex="0"><i class="fa fa-eye text-gradient"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END ENGLISH PART -->
                </div>

                <div class="item col-md-4 wow fadeInUp " style="visibility: visible; animation-name: fadeInUp;">
                    <!-- FRENCH PART -->
                    <div *ngIf="switches.langSwitch == false" class="card-service text-center">
                        <div class="grid-item-over">
                            <div class="item--featured">
                                <img src="{{img}}assets/images/slider/mali.png" class="img-responsive img-center" alt="theme-04" title="theme-04">
                            </div>
                            <div class="item--holder">
                                <div class="item--holder-inner">
                                    <div class="item--icon">
                                        <span class="round-tabs">
                                        <p>2015</p>
                                    </span>
                                    </div>
                                    <h3 class="item--title">
                                        <img src="{{img}}assets/images/logos/Mali-logo.png" class="img-responsive img-center">
                                    </h3>
                                    <div class="item--gap"></div>
                                    <div class="item--content">
                                        L'aventure qui avait commencé au Mali revient à ses origines. la représentation au Mali Modelis Mali est née pour exporter l'expertise de la base du groupe au Mali et accompagner tous les utilisateurs des premiers produits du groupe
                                    </div>

                                </div>
                            </div>
                            <div class="item--meta">
                                <h3 class="item--title">
                                    <a target="_blank" href="https://modelis-mali.com" tabindex="0">
                                        Modelis-Mali</a></h3>
                                <div class="item--readmore">
                                    <a target="_blank" class="more-plus" href="https://modelis-mali.com" tabindex="0"><i class="fa fa-eye text-gradient"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END FRENCH PART -->

                    <!-- ENGLISH PART -->
                    <div *ngIf="switches.langSwitch == true" class="card-service text-center">
                        <div class="grid-item-over">
                            <div class="item--featured">
                                <img src="{{img}}assets/images/slider/mali.png" class="img-responsive img-center" alt="theme-04" title="theme-04">
                            </div>
                            <div class="item--holder">
                                <div class="item--holder-inner">
                                    <div class="item--icon">
                                        <span class="round-tabs">
                                      <p>2015</p>
                                  </span>
                                    </div>
                                    <h3 class="item--title">
                                        <img src="{{img}}assets/images/logos/Mali-logo.png" class="img-responsive img-center">
                                    </h3>
                                    <div class="item--gap"></div>
                                    <div class="item--content">
                                        The adventure that began in Mali returns to its origins. the representation in Mali Modelis Mali was born to export the expertise of the base of the group in Mali and to support all the users of the first products of the group
                                    </div>

                                </div>
                            </div>
                            <div class="item--meta">
                                <h3 class="item--title">
                                    <a target="_blank" href="https://modelis-mali.com" tabindex="0">
                                      Modelis-Mali</a></h3>
                                <div class="item--readmore">
                                    <a target="_blank" class="more-plus" href="https://modelis-mali.com" tabindex="0"><i class="fa fa-eye text-gradient"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END ENGLISH PART -->
                </div>

                <div class="item col-md-4 wow fadeInUp " style="visibility: visible; animation-name: fadeInUp;">
                    <!-- FRENCH PART -->
                    <div *ngIf="switches.langSwitch == false" class="card-service text-center">
                        <div class="grid-item-over">
                            <div class="item--featured">
                                <img src="{{img}}assets/images/slider/GC-photo.png" class="img-responsive img-center" alt="theme-04" title="theme-04">
                            </div>
                            <div class="item--holder">
                                <div class="item--holder-inner">
                                    <div class="item--icon">
                                        <span class="round-tabs">
                                        <p>2018</p>
                                    </span>
                                    </div>
                                    <h3 class="item--title">
                                        <img src="{{img}}assets/images/logos/GC-logo.png" class="img-responsive img-center">
                                    </h3>
                                    <div class="item--gap"></div>
                                    <div class="item--content">
                                        La base du groupe continue de se diversifier avec la création de la division Génie Civil qui opère dans le contexte spécifique des postes électriques.
                                    </div>

                                </div>
                            </div>
                            <div class="item--meta">
                                <h3 class="item--title">
                                    <a target="_blank" href="https://modelis-gc.com" tabindex="0">
                                        Modelis-GC</a></h3>
                                <div class="item--readmore">
                                    <a target="_blank" class="more-plus" href="https://modelis-gc.com" tabindex="0"><i class="fa fa-eye text-gradient"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END FRENCH PART -->

                    <!-- ENGLISH PART -->
                    <div *ngIf="switches.langSwitch == true" class="card-service text-center">
                        <div class="grid-item-over">
                            <div class="item--featured">
                                <img src="{{img}}assets/images/slider/GC-photo.png" class="img-responsive img-center" alt="theme-04" title="theme-04">
                            </div>
                            <div class="item--holder">
                                <div class="item--holder-inner">
                                    <div class="item--icon">
                                        <span class="round-tabs">
                                      <p>2018</p>
                                  </span>
                                    </div>
                                    <h3 class="item--title">
                                        <img src="{{img}}assets/images/logos/GC-logo.png" class="img-responsive img-center">
                                    </h3>
                                    <div class="item--gap"></div>
                                    <div class="item--content">
                                        The base of the group continues to diversify with the creation of the Civil Engineering division which operates in the specific context of electrical substations.
                                    </div>

                                </div>
                            </div>
                            <div class="item--meta">
                                <h3 class="item--title">
                                    <a target="_blank" href="https://modelis-gc.com" tabindex="0">
                                      Modelis-GC</a></h3>
                                <div class="item--readmore">
                                    <a target="_blank" class="more-plus" href="https://modelis-gc.com" tabindex="0"><i class="fa fa-eye text-gradient"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END ENGLISH PART -->
                </div>

                <div class="item col-md-4 wow fadeInUp " style="visibility: visible; animation-name: fadeInUp;">
                    <!-- FRENCH PART -->
                    <div *ngIf="switches.langSwitch == false" class="card-service text-center">
                        <div class="grid-item-over">
                            <div class="item--featured">
                                <img src="{{img}}assets/images/slider/senegal.png" class="img-responsive img-center" alt="theme-04" title="theme-04">
                            </div>
                            <div class="item--holder">
                                <div class="item--holder-inner">
                                    <div class="item--icon">
                                        <span class="round-tabs">
                                        <p>2018</p>
                                    </span>
                                    </div>
                                    <h3 class="item--title">
                                        <img src="{{img}}assets/images/logos/Sénégal-Logo.png" class="img-responsive img-center">
                                    </h3>
                                    <div class="item--gap"></div>
                                    <div class="item--content">
                                        Le groupe Modelis continue son expansion à travers la sous région ouest Africaine en s'installant au Sénégal, le pays de la Teranga.
                                    </div>

                                </div>
                            </div>
                            <div class="item--meta">
                                <h3 class="item--title">
                                    <a target="_blank" href="https://modelis-senegal.com" tabindex="0">
                                        Modelis-Sénegal</a></h3>
                                <div class="item--readmore">
                                    <a target="_blank" class="more-plus" href="https://modelis-senegal.com" tabindex="0"><i class="fa fa-eye text-gradient"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END FRENCH PART -->

                    <!-- ENGLISH PART -->
                    <div *ngIf="switches.langSwitch == true" class="card-service text-center">
                        <div class="grid-item-over">
                            <div class="item--featured">
                                <img src="{{img}}assets/images/slider/senegal.png" class="img-responsive img-center" alt="theme-04" title="theme-04">
                            </div>
                            <div class="item--holder">
                                <div class="item--holder-inner">
                                    <div class="item--icon">
                                        <span class="round-tabs">
                                      <p>2018</p>
                                  </span>
                                    </div>
                                    <h3 class="item--title">
                                        <img src="{{img}}assets/images/logos/Sénégal-Logo.png" class="img-responsive img-center">
                                    </h3>
                                    <div class="item--gap"></div>
                                    <div class="item--content">
                                        The Modelis group continues its expansion through the West African sub-region by settling in Senegal, the country of Teranga.
                                    </div>

                                </div>
                            </div>
                            <div class="item--meta">
                                <h3 class="item--title">
                                    <a target="_blank" href="https://modelis-senegal.com" tabindex="0">
                                      Modelis-Sénegal</a></h3>
                                <div class="item--readmore">
                                    <a target="_blank" class="more-plus" href="https://modelis-senegal.com" tabindex="0"><i class="fa fa-eye text-gradient"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- END ENGLISH PART -->
                </div>

            </div>
            <button class="btn btn-primary leftLst">
                <i class="fa fa-angle-left"></i>
            </button>
            <button class="btn btn-primary rightLst">
                <i class="fa fa-angle-right"></i>
            </button>
        </div>
    </div>
    <div *ngIf="switches.langSwitch == false" class="row">
        <div class="col-md-12 text-center">
            <p>Historique de création du groupe Modelis</p>
        </div>
    </div>
    <div *ngIf="switches.langSwitch == true" class="row">
        <div class="col-md-12 text-center">
            <p>Creation history of the Modelis group</p>
        </div>
    </div>
</div>