import { SwitchService } from './../../service/langue/switch.service';
import { Component, OnInit } from '@angular/core';
import {Variable} from '../../service/Variable';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  constructor(private variable : Variable, private lang: SwitchService) { }
  img = this.variable.BASE_IMG;
  switches: any;
  ngOnInit(): void {
    this.switches = this.lang;
  }
}
