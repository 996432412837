import { SwitchService } from './service/langue/switch.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ContactsComponent } from './page/contacts/contacts.component';
import { AccueilComponent } from './page/accueil/accueil.component';
import {APP_BASE_HREF} from '@angular/common';
import {Variable} from './service/Variable';
import { FooterComponent } from './page/footer/footer.component';
import { SliderComponent } from './page/accueil/slider/slider.component';
import { AproposComponent } from './page/apropos/apropos.component';
import { ReferencesComponent } from './page/references/references.component';
import { RealisationComponent } from './page/realisation/realisation.component';
import { SliderModelisGroupComponent } from './page/slider-modelis-group/slider-modelis-group.component';
import { ServicesComponent } from './page/services/services.component';
import { BlogComponent } from './page/blog/blog.component';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import { RefreshComponent } from './page/refresh/refresh.component';

const routes: Routes = [
  { path: '', component: AccueilComponent},
  { path: '**', component: AccueilComponent },
  // redirect to `first-component`
]

@NgModule({
  declarations: [
    AppComponent,
    ContactsComponent,
    AccueilComponent,
    FooterComponent,
    SliderComponent,
    AproposComponent,
    ReferencesComponent,
    RealisationComponent,
    SliderModelisGroupComponent,
    ServicesComponent,
    BlogComponent,
    RefreshComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    FormsModule,
  ],
  providers: [
    {provide: APP_BASE_HREF, useValue: '/'} , Variable, SwitchService
    //   {provide: APP_BASE_HREF, useValue: '/'} , Variable,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
