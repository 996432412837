<div class="session" id="contact">
    <div class="container wow fadeInUp ">
        <div *ngIf="switches.langSwitch == false" class="row">
            <div class="col-md-6 ">
                <div class=" realisation">
                    <h3 class="item--title st-line-left text-white">
                        <span><i></i></span> Contactez-nous
                    </h3>
                </div>
                <div class="row">
                    <div class="col-md-12  wow fadeInUp">
                        <div class=" contact-item ">
                            <div class="item--icon  text-center">
                                <i aria-hidden="true" class="fa fa-map-marker text-gradient"></i>
                            </div>
                            <div class="item--holder ">
                                <h3 class="item--title">L'adresse de notre siège social </h3>
                                <div class="item--description">Cocody Abatta, Agence SODECI 28 BP 1402 ABIDJAN 28 Côte d'Ivoire
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 wow fadeInUp ">
                        <div class="contact-item ">

                            <div class="item--icon  text-center">
                                <i aria-hidden="true" class="fa fa-phone text-gradient"></i>
                            </div>
                            <div class="item--holder ">
                                <h3 class="item--title">Notre numéro de téléphone </h3>
                                <div class="item--description">00 225 22 47 64 59
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 wow fadeInUp ">
                        <div class="contact-item">
                            <div class="item--icon  text-center">
                                <i aria-hidden="true" class="fa fa-phone text-gradient"></i>
                            </div>
                            <div class="item--holder ">
                                <h3 class="item--title">Notre adresse mail </h3>
                                <div class="item--description"><a href="mailto:contact@modelisgroup.com" class="text-white-50" target="_top">contacts@modelisgroup.com</a>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12  wow fadeInUp">
                        <div class=" map-group wow fadeInUp">
                            <h1 class="col-md-12mb-4 mb-lg-4 " data-aos="fade-up">
                                Le Groupe MODELIS dans le monde
                            </h1>
                            <p class="col-md-12" data-aos="fade-up" style="margin-left: 0px;padding-left: 0px!important;">Nos Implantations dans le monde</p>
                            <div id="map" class="wow zoomIn map" data-aos="fade-up"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6  wow fadeInUp">
                <div class="col-md-12 contact">
                    <p class="mb-4 text-white " style="padding: 0px 0px 20px">
                        Notre équipe se fera un plaisir de répondre à vos différentes questions. N'hésitez pas à nous contacter.
                        <br>
                    </p>
                    <form class="row" name="form" #form="ngForm" (ngSubmit)="onSubmit(form)">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input class="form-control valid" [(ngModel)]="contacts.nom" #nomInput="ngModel" name="nom" required="" size="40" type="text" placeholder="Votre nom *">
                                <span class="mat-error " style="color: #bf8a8a" *ngIf="nomInput.invalid && nomInput.touched">
                                    Le nom est obligatoire
                                  </span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input class="form-control valid" [(ngModel)]="contacts.prenom" #prenomInput="ngModel" name="nom" required="" size="40" type="text" placeholder="Votre nom *">
                                <span class="mat-error " style="color: #bf8a8a" *ngIf="prenomInput.invalid && prenomInput.touched">
                                    Le prenom est obligatoire
                                  </span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input class="form-control valid" type="email" #emailInput="ngModel" [email]="true" [(ngModel)]="contacts.email" name="email" required="" placeholder="Votre adresse mail *">
                                <span class="mat-error" style="color: #bf8a8a" *ngIf="emailInput.invalid && emailInput.touched ">
                                    Veuillez entrer un email valide
                                  </span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input class="form-control" type="number" [(ngModel)]="contacts.telephone" #telephoneInput="ngModel" name="objet" required="" placeholder="Votre numéro de téléphone">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <input class="form-control" type="text" [(ngModel)]="contacts.objet" #objetInput="ngModel" name="objet" required="" placeholder="Objet">
                                <span class="mat-error" style="color: #bf8a8a" *ngIf=" objetInput.invalid && objetInput.touched">
                                    L'objet est obligatoire
                                  </span>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <textarea class="form-control w-100" type="text" [(ngModel)]="contacts.message" #messageInput="ngModel" name="message" required="" id="message" rows="5" placeholder="Message ..."></textarea>
                                <span class="mat-error" style="color: #bf8a8a" *ngIf=" messageInput.invalid && messageInput.touched ">
                                    Le message est obligatoire
                                  </span>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <button *ngIf="form.valid" type="submit" class="btn btn-primary col-md-12"><i
                                        class="fac fac-check-circle"></i>
                                    Envoyer
                                </button>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div *ngIf="error == false && success==true" class="alert alert-success mt-3 mb-0" style="text-align: center;">{{successmes}}</div>
                            <div *ngIf="error == true && success==false" class="alert alert-danger mt-3 mb-0" style="text-align: center;">{{errormes}}</div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div *ngIf="switches.langSwitch == true" class="row">
            <div class="col-md-6 ">
                <div class=" realisation">
                    <h3 class="item--title st-line-left text-white">
                        <span><i></i></span> Contact Us
                    </h3>
                </div>
                <div class="row">
                    <div class="col-md-12  wow fadeInUp">
                        <div class=" contact-item ">
                            <div class="item--icon  text-center">
                                <i aria-hidden="true" class="fa fa-map-marker text-gradient"></i>
                            </div>
                            <div class="item--holder ">
                                <h3 class="item--title">Address of our head office </h3>
                                <div class="item--description">Cocody Abatta, Agence SODECI 28 BP 1402 ABIDJAN 28 Côte d'Ivoire
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 wow fadeInUp ">
                        <div class="contact-item ">

                            <div class="item--icon  text-center">
                                <i aria-hidden="true" class="fa fa-phone text-gradient"></i>
                            </div>
                            <div class="item--holder ">
                                <h3 class="item--title">Our phone number </h3>
                                <div class="item--description">00 225 22 47 64 59
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 wow fadeInUp ">
                        <div class="contact-item">
                            <div class="item--icon  text-center">
                                <i aria-hidden="true" class="fa fa-phone text-gradient"></i>
                            </div>
                            <div class="item--holder ">
                                <h3 class="item--title">Our email address </h3>
                                <div class="item--description"><a href="mailto:contact@modelisgroup.com" class="text-white-50" target="_top">contacts@modelisgroup.com</a>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12  wow fadeInUp">
                        <div class=" map-group wow fadeInUp">
                            <h1 class="col-md-12mb-4 mb-lg-4 " data-aos="fade-up">
                                MODELIS Group in the world
                            </h1>
                            <p class="col-md-12" data-aos="fade-up" style="margin-left: 0px;padding-left: 0px!important;">Our locations in the world</p>
                            <div id="map" class="wow zoomIn map" data-aos="fade-up"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6  wow fadeInUp">
                <div class="col-md-12 contact">
                    <p class="mb-4 text-white " style="padding: 0px 0px 20px">
                        That will be a pleasure for our team to answer your questions. Don't hesitate to contact us.
                        <br>
                    </p>
                    <form class="row" name="form" #form="ngForm" (ngSubmit)="onSubmit(form)">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input class="form-control valid" [(ngModel)]="contacts.nom" #nomInput="ngModel" name="nom" required="" size="40" type="text" placeholder="Your Last Name *">
                                <span class="mat-error " style="color: #bf8a8a" *ngIf="nomInput.invalid && nomInput.touched">
                                  Last Name is required
                                </span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input class="form-control valid" [(ngModel)]="contacts.prenom" #prenomInput="ngModel" name="nom" required="" size="40" type="text" placeholder="Your First Name *">
                                <span class="mat-error " style="color: #bf8a8a" *ngIf="prenomInput.invalid && prenomInput.touched">
                                  First Name is require
                                </span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input class="form-control valid" type="email" #emailInput="ngModel" [email]="true" [(ngModel)]="contacts.email" name="email" required="" placeholder="Your mail *">
                                <span class="mat-error" style="color: #bf8a8a" *ngIf="emailInput.invalid && emailInput.touched ">
                                  Submit a valid email
                                </span>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input class="form-control" type="number" [(ngModel)]="contacts.telephone" #telephoneInput="ngModel" name="objet" required="" placeholder="Your Phone Number">
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <input class="form-control" type="text" [(ngModel)]="contacts.objet" #objetInput="ngModel" name="objet" required="" placeholder="Oject">
                                <span class="mat-error" style="color: #bf8a8a" *ngIf=" objetInput.invalid && objetInput.touched">
                                  Object is required
                                </span>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <textarea class="form-control w-100" type="text" [(ngModel)]="contacts.message" #messageInput="ngModel" name="message" required="" id="message" rows="5" placeholder="Message ..."></textarea>
                                <span class="mat-error" style="color: #bf8a8a" *ngIf=" messageInput.invalid && messageInput.touched ">
                                  The message is required
                                </span>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <button *ngIf="form.valid" type="submit" class="btn btn-primary col-md-12"><i
                                      class="fac fac-check-circle"></i>
                                  Send
                              </button>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div *ngIf="error == false && success==true" class="alert alert-success mt-3 mb-0" style="text-align: center;">{{successmes}}</div>
                            <div *ngIf="error == true && success==false" class="alert alert-danger mt-3 mb-0" style="text-align: center;">{{errormes}}</div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>