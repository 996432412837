import { SwitchService } from './../../service/langue/switch.service';
import { Component, OnInit } from '@angular/core';
import {Variable} from '../../service/Variable';

@Component({
  selector: 'app-slider-modelis-group',
  templateUrl: './slider-modelis-group.component.html',
  styleUrls: ['./slider-modelis-group.component.css']
})
export class SliderModelisGroupComponent implements OnInit {

  constructor(private variable : Variable, private lang: SwitchService) { }
  switches: any;
  img = this.variable.BASE_IMG;
  ngOnInit(): void {
    this.switches = this.lang;

  }

}
