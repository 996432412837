import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SwitchService {
  langSwitch: boolean = false;
  flagImagesSrc = [
    './../assets/images/en-us-flag.png',
    './../assets/images/fr-flag.png'
  ]
  flagImage: string = this.flagImagesSrc[1];
  constructor() { }

  switchLanguage(){
    this.langSwitch = !this.langSwitch;
    if(this.langSwitch === false){
      this.flagImage = this.flagImagesSrc[1];
    }else{
      this.flagImage = this.flagImagesSrc[0];
    }
  }
}
