<div class="session service-section" id="expertise">
    <!-- FRENCH PART -->
    <div *ngIf="switches.langSwitch == false" class="container">
        <div class="row">
            <div class="col-md-6 serives">
                <h3 class="item--title st-line-left">
                    <span>
                            <i></i>
                        Notre Expertise
                </span>
                    <p class="text-white">
                        Notre savoir faire
                    </p>
                </h3>

            </div>
            <div class="col-md-6">

            </div>
        </div>
        <div class="cadre-service-grid style2">
            <div class="ct-grid-inner  row animate-time3">
                <div class="row">
                    <div class=" col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-lg-4 mb-4 wow fadeInUp" style="animation-delay: 500ms; visibility: visible; animation-name: fadeInUp;">
                        <div class="grid-item ">
                            <div class="grid-item-inner ">
                                <div class="grid-item-holder">
                                    <div class="item--overlay"></div>

                                    <div class="item--icon"><i class="text-gradient flaticon-target-1"></i></div>

                                    <img src="{{img}}assets/images/expertise/collecter.jpg" class="img-responsive ">
                                    <h3 class="item--title text-center">COLLECTER</h3>
                                    <div class="item--content">
                                        <ul>
                                            <li><i class="fa fa-angle-double-right"></i>Collecte de données de terrain; travaux de topographie
                                            </li>
                                            <li><i class="fa fa-angle-double-right"></i>Vente et location d’appareil de mesure
                                            </li>
                                            <li><i class="fa fa-angle-double-right"></i>Maintenance et réparation d’appareil
                                                <br><br></li>
                                        </ul>
                                    </div>
                                    <div class="item--icon-abs"><i class="text-gradient flaticon-target-1"></i></div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-lg-4 mb-4 wow fadeInUp" style="animation-delay: 500ms; visibility: visible; animation-name: fadeInUp;">
                        <div class="grid-item ">
                            <div class="grid-item-inner">
                                <div class="grid-item-holder">
                                    <div class="item--overlay"></div>

                                    <div class="item--icon"><i class="text-gradient flaticon-target-1"></i></div>

                                    <img src="{{img}}assets/images/expertise/traiter.png" class="img-responsive ">
                                    <h3 class="item--title text-center">TRAITER</h3>
                                    <div class="item--content">
                                        <ul>
                                            <li><i class="fa fa-angle-double-right"></i>Analyse Spatiale, Intégration de données
                                            </li>
                                            <li><i class="fa fa-angle-double-right"></i>Intégration et customisation de solutions
                                            </li>
                                            <li><i class="fa fa-angle-double-right"></i>Développement de solution métier (cadastre, Administration foncière, défense, environnement)
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="item--icon-abs"><i class="text-gradient flaticon-target-1"></i></div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class=" col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-lg-4 mb-4 wow fadeInUp" style="animation-delay: 700ms; visibility: visible; animation-name: fadeInUp;">
                        <div class="grid-item ">
                            <div class="grid-item-inner">
                                <div class="grid-item-holder">
                                    <div class="item--overlay"></div>

                                    <div class="item--icon"><i class="text-gradient flaticon-target-1"></i></div>

                                    <img src="{{img}}assets/images/expertise/diffuser.png" class="img-responsive ">
                                    <h3 class="item--title text-center">DIFFUSER</h3>
                                    <div class="item--content">
                                        <ul>
                                            <li><i class="fa fa-angle-double-right"></i>SIG/ Cartographie</li>
                                            <li><i class="fa fa-angle-double-right"></i>Sites internet et applications mobiles
                                            </li>
                                            <li><i class="fa fa-angle-double-right"></i>Formation</li>
                                        </ul>
                                    </div>
                                    <div class="item--icon-abs"><i class="text-gradient flaticon-target-1"></i></div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-lg-4 mb-4 wow fadeInUp" style="animation-delay: 700ms; visibility: visible; animation-name: fadeInUp;">
                        <div class="grid-item ">
                            <div class="grid-item-inner">
                                <div class="grid-item-holder">
                                    <div class="item--overlay"></div>

                                    <div class="item--icon"><i class="text-gradient flaticon-target-1"></i></div>

                                    <img src="{{img}}assets/images/expertise/construire.png" class="img-responsive ">
                                    <h3 class="item--title text-center">CONSTRUIRE</h3>
                                    <div class="item--content">
                                        <ul>
                                            <li><i class="fa fa-angle-double-right"></i>Ingénierie de projet de génie civil
                                            </li>
                                            <li><i class="fa fa-angle-double-right"></i>Réalisation de postes électriques
                                            </li>
                                            <li><i class="fa fa-angle-double-right"></i>Travaux de construction et de rénovation de bâtiment
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="item--icon-abs"><i class="text-gradient flaticon-target-1"></i></div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END FRENCH PART -->

    <!--English part-->
    <div *ngIf="switches.langSwitch == true" class="container">
        <div class="row">
            <div class="col-md-6 serives">
                <h3 class="item--title st-line-left">
                    <span>
                          <i></i>
                      Our Expertise
              </span>
                    <p class="text-white">
                        Our Know-How
                    </p>
                </h3>

            </div>
            <div class="col-md-6">

            </div>
        </div>
        <div class="cadre-service-grid style2">
            <div class="ct-grid-inner  row animate-time3">
                <div class="row">
                    <div class=" col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-lg-4 mb-4 wow fadeInUp" style="animation-delay: 500ms; visibility: visible; animation-name: fadeInUp;">
                        <div class="grid-item ">
                            <div class="grid-item-inner ">
                                <div class="grid-item-holder">
                                    <div class="item--overlay"></div>

                                    <div class="item--icon"><i class="text-gradient flaticon-target-1"></i></div>

                                    <img src="{{img}}assets/images/expertise/collecter.jpg" class="img-responsive ">
                                    <h3 class="item--title text-center">COLLECT</h3>
                                    <div class="item--content">
                                        <ul>
                                            <li><i class="fa fa-angle-double-right"></i>Land data Collection; topography work
                                            </li>
                                            <li><i class="fa fa-angle-double-right"></i>Sales and rental of measuring device
                                            </li>
                                            <li><i class="fa fa-angle-double-right"></i>Device maintenance and repair
                                                <br><br></li>
                                        </ul>
                                    </div>
                                    <div class="item--icon-abs"><i class="text-gradient flaticon-target-1"></i></div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-lg-4 mb-4 wow fadeInUp" style="animation-delay: 500ms; visibility: visible; animation-name: fadeInUp;">
                        <div class="grid-item ">
                            <div class="grid-item-inner">
                                <div class="grid-item-holder">
                                    <div class="item--overlay"></div>

                                    <div class="item--icon"><i class="text-gradient flaticon-target-1"></i></div>

                                    <img src="{{img}}assets/images/expertise/traiter.png" class="img-responsive ">
                                    <h3 class="item--title text-center">PROCESS</h3>
                                    <div class="item--content">
                                        <ul>
                                            <li><i class="fa fa-angle-double-right"></i>Spatial analysis, data integration
                                            </li>
                                            <li><i class="fa fa-angle-double-right"></i>Integration and customization of solutions
                                            </li>
                                            <li><i class="fa fa-angle-double-right"></i>Business solution development (land registry, land administration, defense, environment)
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="item--icon-abs"><i class="text-gradient flaticon-target-1"></i></div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class=" col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-lg-4 mb-4 wow fadeInUp" style="animation-delay: 700ms; visibility: visible; animation-name: fadeInUp;">
                        <div class="grid-item ">
                            <div class="grid-item-inner">
                                <div class="grid-item-holder">
                                    <div class="item--overlay"></div>

                                    <div class="item--icon"><i class="text-gradient flaticon-target-1"></i></div>

                                    <img src="{{img}}assets/images/expertise/diffuser.png" class="img-responsive ">
                                    <h3 class="item--title text-center">DISSEMINATE</h3>
                                    <div class="item--content">
                                        <ul>
                                            <li><i class="fa fa-angle-double-right"></i>GIS/ Cartography</li>
                                            <li><i class="fa fa-angle-double-right"></i>Websites and mobile applications
                                            </li>
                                            <li><i class="fa fa-angle-double-right"></i>Training</li>
                                        </ul>
                                    </div>
                                    <div class="item--icon-abs"><i class="text-gradient flaticon-target-1"></i></div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class=" col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 mb-lg-4 mb-4 wow fadeInUp" style="animation-delay: 700ms; visibility: visible; animation-name: fadeInUp;">
                        <div class="grid-item ">
                            <div class="grid-item-inner">
                                <div class="grid-item-holder">
                                    <div class="item--overlay"></div>

                                    <div class="item--icon"><i class="text-gradient flaticon-target-1"></i></div>

                                    <img src="{{img}}assets/images/expertise/construire.png" class="img-responsive ">
                                    <h3 class="item--title text-center">BUILD</h3>
                                    <div class="item--content">
                                        <ul>
                                            <li><i class="fa fa-angle-double-right"></i>Engineering Project of Civil Engineer
                                            </li>
                                            <li><i class="fa fa-angle-double-right"></i>Realization of electrical substations
                                            </li>
                                            <li><i class="fa fa-angle-double-right"></i>Construction and renovation of building
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="item--icon-abs"><i class="text-gradient flaticon-target-1"></i></div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END ENGLISH PART -->
</div>
