import { SwitchService } from './../../service/langue/switch.service';
import { AppComponent } from './../../app.component';
import { Component, OnInit } from '@angular/core';
import {Variable} from '../../service/Variable';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.css']
})
export class AccueilComponent implements OnInit {

  constructor(private variable : Variable, public route : Router, public _location : Location, private lang: SwitchService) { }
  img = this.variable.BASE_IMG;
  switches: any;

  ngOnInit(): void {
    this.switches = this.lang;
  }
  switcher(){
    this.refresh();
    this.lang.switchLanguage();
  }

  refresh(){
    this.route.navigateByUrl('/refresh', {skipLocationChange : true}).then(()=>{
      //console.log(decodeURI(this._location.path()));
      this.route.navigate([decodeURI(this._location.path())]);
      });
  }

}
