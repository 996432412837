<section>
    <div style=" top: -65px; z-index: -2;">
        <div *ngIf="switches.langSwitch == false" class="header-wrapper">
            <div id="inSlider" class="carousel slide carousel-fade " data-ride="carousel">
                <ol class="carousel-indicators">
                    <li data-target="#inSlider" data-slide-to="0" class="active"></li>
                    <li data-target="#inSlider" data-slide-to="1"></li>
                    <li data-target="#inSlider" data-slide-to="2"></li>
                    <li data-target="#inSlider" data-slide-to="3"></li>
                </ol>
                <div class="carousel-inner" role="listbox">
                    <div class="item active">
                        <div class="container z-index-1">
                            <div class="carousel-caption">
                                <div class="col-md-10">
                                    <h1>COLLECTER</h1>
                                    <p>
                                        Nous collectons toutes vos données terrain via des appareils de haute performance
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Set background for slide in css -->
                        <div class="header-back one zoominheader z-index--1"></div>

                    </div>
                    <div class="item">
                        <div class="container z-index-1">
                            <div class="carousel-caption blank">
                                <div class="col-md-10">
                                    <h1>TRAITER</h1>
                                    <p>
                                        Nous avons une pleine maitrise des logiciels SIG pour traiter toutes vos données
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Set background for slide in css -->
                        <div class="header-back two zoominheader z-index--1"></div>
                    </div>
                    <div class="item">
                        <div class="container z-index-1">
                            <div class="carousel-caption blank ">
                                <div class="col-md-10">
                                    <h1>DIFFUSER</h1>
                                    <p></p>
                                    <p>
                                        Nous reconstituons les informations utiles à l’analyse et la prise de décision sur des applications mobiles ou internet. </p>
                                </div>
                            </div>
                        </div>
                        <!-- Set background for slide in css -->
                        <div class="header-back three zoominheader z-index--1 "></div>
                    </div>
                    <div class="item">
                        <div class="container z-index-1">
                            <div class="carousel-caption blank ">
                                <div class="col-md-10">
                                    <h1>CONSTRUIRE</h1>
                                    <p></p>
                                    <p>Nous construisons avec qualité vos postes électriques et vos bâtiments </p>
                                </div>
                            </div>
                        </div>
                        <!-- Set background for slide in css -->
                        <div class="header-back four zoominheader z-index--1"></div>
                    </div>
                </div>
                <a class="left carousel-control" href="#inSlider" role="button" data-slide="prev">
                    <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="right carousel-control" href="#inSlider" role="button" data-slide="next">
                    <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>


        </div>
        <!-- English Part -->
        <div *ngIf="switches.langSwitch == true" class="header-wrapper">
            <div id="inSlider" class="carousel slide carousel-fade " data-ride="carousel">
                <ol class="carousel-indicators">
                    <li data-target="#inSlider" data-slide-to="0" class="active"></li>
                    <li data-target="#inSlider" data-slide-to="1"></li>
                    <li data-target="#inSlider" data-slide-to="2"></li>
                    <li data-target="#inSlider" data-slide-to="3"></li>
                </ol>
                <div class="carousel-inner" role="listbox">
                    <div class="item active">
                        <div class="container z-index-1">
                            <div class="carousel-caption">
                                <div class="col-md-10">
                                    <h1>COLLECT</h1>
                                    <p>
                                        We collect all your land data via high performance device
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Set background for slide in css -->
                        <div class="header-back one zoominheader z-index--1"></div>

                    </div>
                    <div class="item">
                        <div class="container z-index-1">
                            <div class="carousel-caption blank">
                                <div class="col-md-10">
                                    <h1>PROCESS</h1>
                                    <p>
                                        We have full control of GIS software to process all your data
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Set background for slide in css -->
                        <div class="header-back two zoominheader z-index--1"></div>
                    </div>
                    <div class="item">
                        <div class="container z-index-1">
                            <div class="carousel-caption blank ">
                                <div class="col-md-10">
                                    <h1>DISSIMINATE</h1>
                                    <p></p>
                                    <p>
                                        We reconstitute useful information for analysis and decision-making on mobile applications or Internet
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Set background for slide in css -->
                        <div class="header-back three zoominheader z-index--1 "></div>
                    </div>
                    <div class="item">
                        <div class="container z-index-1">
                            <div class="carousel-caption blank ">
                                <div class="col-md-10">
                                    <h1>BUILD</h1>
                                    <p></p>
                                    <p>We build with quality your substations and buildings </p>
                                </div>
                            </div>
                        </div>
                        <!-- Set background for slide in css -->
                        <div class="header-back four zoominheader z-index--1"></div>
                    </div>
                </div>
                <a class="left carousel-control" href="#inSlider" role="button" data-slide="prev">
                    <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="right carousel-control" href="#inSlider" role="button" data-slide="next">
                    <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>


        </div>
    </div>
</section>