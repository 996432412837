import { SwitchService } from './../../service/langue/switch.service';
import { Component, OnInit } from '@angular/core';
import {Variable} from '../../service/Variable';

@Component({
  selector: 'app-realisation',
  templateUrl: './realisation.component.html',
  styleUrls: ['./realisation.component.css']
})
export class RealisationComponent implements OnInit {

  constructor(private variable : Variable, private lang: SwitchService) { }
  img = this.variable.BASE_IMG;
  switches: any;
  ngOnInit(): void {
    this.switches = this.lang;
  }

}
